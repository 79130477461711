// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCHkt_F1D6gmSfLwidq5pmBhx9dSkcuwVg",
  authDomain: "birthday-covid.firebaseapp.com",
  databaseURL: "https://birthday-covid-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "birthday-covid",
  storageBucket: "birthday-covid.appspot.com",
  messagingSenderId: "715732254003",
  appId: "1:715732254003:web:d1dc6c8e2b9a30b03dd246",
  measurementId: "G-VPX06RH79Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = getFirestore();