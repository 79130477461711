export const EventLabels = Object.freeze({
    CAKE: "Cake",
    FAB: "FAB",
    CTA: "CTA",
    Event: "Event"
});

export const EventNames = Object.freeze({
    AUDIO_INITIALIZED: "Audio Initialized",
    CANDLE_BLOWN: "Candle Blown",
    CAKE_CUT: "Cake Cut",
    BLOWER_DRAGGED: "Blower Dragged",
    KNIFE_DRAGGED: "Knife Dragged",
    HOME_CLICKED: "Home Clicked",
    ABOUT_CLICKED: "About Clicked",
    FEEDBACK_CLICKED: "Feedback Clicked",
    TOGGLE_SOUND_CLICKED: "Toggle Sound Clicked",
    SEE_DEMO_CLICKED: "See Demo Clicked",
    CREATE_PARTY_CLICKED: "Create Party Clicked",
    GET_LINK_CLICKED: "Get Link Clicked",
    GET_LINK_CLICKED_SUCCESS: "Get Link Clicked Success",
    GET_LINK_CLICKED_ERRORED: "Get Link Clicked Errored",
    SEND_BDAY_MESSAGE_CLICKED: "Send Bday Message Clicked",
    SEND_BDAY_MESSAGE_CLICKED_SUCCESS: "Send Bday Message Clicked Success",
    SEND_BDAY_MESSAGE_CLICKED_ERRORED: "Send Bday Message Clicked Errored",
    COPY_LINK_CLICKED: "Copy Link Clicked",
    COPY_LINK_CLICKED_SUCCESS: "Copy Link Clicked Success",
    COPY_LINK_CLICKED_ERRORED: "Copy Link Clicked Errored",
    COPY_LINK_FRIENDS_CLICKED: "Copy Link Friends Clicked",
    COPY_LINK_FRIENDS_CLICKED_SUCCESS: "Copy Link Friends Clicked Success",
    COPY_LINK_FRIENDS_CLICKED_ERRORED: "Copy Link Friends Clicked Errored",
    SHARE_LINK_WHATSAPP_CLICKED: "Share Link Whatsapp Clicked",
    SHARE_LINK_FRIENDS_WHATSAPP_CLICKED: "Share Link Friends Whatsapp Clicked"
});

export function sendClickEvent(eventLabel, eventAction) {
    if (typeof window !== 'undefined' && 'ga' in window) {
        window.ga('send', {
            hitType: 'event',
            eventCategory: 'Click',
            eventAction,
            eventLabel
          });
    }
}

export function sendDragEvent(eventLabel, eventAction) {
    if (typeof window !== 'undefined' && 'ga' in window) {
        window.ga('send', {
            hitType: 'event',
            eventCategory: 'Drag',
            eventAction,
            eventLabel
          });
    }
}

export function sendCustomEvent(eventLabel, eventAction, customProps) {
    if (typeof window !== 'undefined' && 'ga' in window) {
        window.ga('send', {
            hitType: 'event',
            eventCategory: 'Custom',
            eventAction,
            eventLabel,
            ...customProps,
          });
    }
}