import React from 'react';

const iconTypeMap = {
  SOUND_ON: 'fa-volume-up',
  SOUND_OFF: 'fa-volume-mute',
  HOME: 'fa-home',
  FEEDBACK: 'fa-comment-alt',
  ABOUT: 'fa-question-circle'
}

export const iconTypes = {
  SOUND_ON: 'SOUND_ON',
  SOUND_OFF: 'SOUND_OFF',
  HOME: 'HOME',
  FEEDBACK: 'FEEDBACK',
  ABOUT: 'ABOUT',
};

const corners = [
  'top-left',
  'top-right',
  'bottom-left',
  'bottom-right',
];

const getClassForDirection = (direction) => {
  switch(direction) {
    case 'left':
      return 'fab--left';
    case 'right':
      return 'fab--right';
    case 'top':
      return 'fab--top';
    case 'bottom':
      return 'fab--bottom';
    default:
      return '';
  }
}

const cornerClassesMap = corners.reduce((classMap, corner) => {
  classMap[corner] = corner.split('-').map(getClassForDirection).join(' ');
  return classMap;
}, {});



const FloatingActionButton = ({
  iconType,
  onClick,
  corner='top-left'
}) => {

  return (
    <button className={`button fab ${cornerClassesMap[corner]}`} onClick={onClick}>
      <span className="icon">
        <i className={`fas ${iconTypeMap[iconType]}`}></i>
      </span>
    </button>
  )
}

export default FloatingActionButton;
