import React from 'react';
// import CreatePartyForm from '../form';

const FeedbackModal = ({ isModalActive, onClose }) => {

  return (
    <div className={`modal ${isModalActive ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={onClose}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">We'd love to hear from you!</p>
            <button
              className="delete"
              aria-label="close"
              onClick={onClose}
            ></button>
          </header>
          <section className="modal-card-body">
            Feel free to reach out to us at: <a href="mailto:feedback.birthdaycovid@gmail.com">feedback.birthdaycovid@gmail.com</a>
          </section>
          <footer className="modal-card-foot"/>
        </div>
      </div>
  );
}

export default FeedbackModal;