import { collection, doc, getDoc, getDocs, query, addDoc, setDoc, updateDoc, where, collectionGroup, arrayUnion } from "firebase/firestore";
import { db } from '../config/firebaseConfig';

export const getParty = async (party_id) => {
    if (!party_id) return {}
    const docSnap = await getDoc(doc(db, "party", party_id))
    return docSnap.exists() ? docSnap.data() : {}
}

export const getPartyMessages = async (party_id, code) => {
    if (!party_id || !code) return {}   
    const docs = await getDocs(query(collectionGroup(db, "messages"), where('partyId','==',party_id), where('code','==',code)))
    let finalDoc = {}
    docs.forEach(doc => {
        finalDoc = doc.data()
    })
    return finalDoc
}

export const createParty = async (formData = {}, code) => {
    const docRef = await addDoc(collection(db, "party"), formData);
    await setDoc(doc(db, "party", docRef.id, "messages", docRef.id), {
                                partyId: docRef.id,
                                code,
                                messages: []
                            })
    return docRef.id;
}

export const addMessage = async (partyId, message) => {
    const msgRef = doc(db, "party", partyId, "messages", partyId)
    return await updateDoc(msgRef, {
        messages: arrayUnion(message) 
    })
}
